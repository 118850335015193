/*
 *   File : user-settings.js
 *   Author URI : www.evoqins.com
 *   Description : Container page for user category settings
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// Components
import { CustomTitle } from "../../Components/Title";
import { DataTableContainer } from "../../Components/DataTable";
import { DeleteConfirmationModal } from "../../Components/Modal";
import { EmptyScreen, Toggle } from "../../Components/Others";

// Styles
import Colors from "../../Styles/color.module.scss";

// Services
import APIService from "../../Services/api-service";
import { DownloadButton } from "../../Components/Buttons";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const AMCList = () => {
    const [amcData, setAmcData] = useState(null);
    const [amcList, setAmcList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);


    useEffect(() => {
        _getAmcList();
    }, []);

    useEffect(() => {
        if (amcData !== null) {
            const modal = new Modal(document.getElementById("delete-modal"), {});
            modal.show();
        }
    }, [amcData]);


    function _handleToggle(data) {
        setAmcData(data);
    }




    // API -get investor types

    const _getAmcList = () => {
        const url = "/fund/amc-list";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setAmcList(response.data);
            } else {
                setAmcList([]);
            }
            setPageLoader(false);
        })
    }


    // API - delete nudge
    const _manageAmc = async () => {

        let status = false
        setApiLoader(true);

        const url = "/fund/amc_enable"
        const request = {
            amc_id: amcData.id
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                status = true;
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }

    // API - download list 
    const _downloadList = () => {
        setDownloadLoader(true);
        const url = "/fund/download";


        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("User list downloaded successfully", {
                    type: "success"
                });
                window.open(response.data.url, "_blank")
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setDownloadLoader(false)
        });
    }

    const AMC_COLUMNS = [
        {
            name: "AMC Code",
            width: "200px",
            cell: row => <div className="d-flex align-items-center gap-8px">
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.amc_code}</p>
            </div>
        },
        {
            name: "Name",
            width: "360px",
            cell: row => <div className="d-flex align-items-center gap-8px">
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.name}</p>
            </div>
        },
        {
            name: "Status",
            // width: "100px",
            cell: row => <div className="d-flex gap-16px">
                <Toggle check={row.is_active}
                    onChange={() => _handleToggle(row)} />
            </div>
        }
    ]


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr d-flex">
                <CustomTitle title="AMC List" type={1} />

            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px py-4">
                    <div className="padding-52px-lr d-flex justify-content-end">
                        <DownloadButton
                            disabled={false}
                            className=""
                            label="Download MF Data"
                            loader={downloadLoader}
                            onPress={_downloadList} />
                    </div>
                    {
                        pageLoader === true ?
                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                    alt={"loader"}
                                    width={60}
                                    height={60}
                                />
                            </div>
                            :
                            amcList.length === 0 ?
                                <EmptyScreen className="my-5"
                                    title="No AMC list found"
                                    image={require("../../Assets/Images/Empty/no-data.png")}
                                    description="" />
                                :
                                <DataTableContainer data={amcList}
                                    columns={AMC_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                    }
                </div>
            </div>
            {
                amcData !== null &&
                <DeleteConfirmationModal title="Manage AMC"
                    description={`Are you sure you want to ${amcData.is_active ? "Deactivate" : "Activate"} this AMC "${amcData.name}"`}
                    confirm={_manageAmc}
                    label="Switch"
                    updateList={_getAmcList}
                    close={() => _handleToggle(null)}
                    loader={apiLoader} />

            }
        </Fragment>
    )
}

export default AMCList;