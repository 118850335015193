/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Fund list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";


// Custom components
import { CustomTitle } from "../../Components/Title";
import { PrimaryButton, SecondaryButton, TextButton } from "../../Components/Buttons";
import { AddFundsModal, ConfirmDeleteModal, ManageAssetClass } from "../../Components/Modal";
import { CustomTabBar } from "../../Components/Tab";

// Custom style

// Services
import APIService from "../../Services/api-service";

const FundListing = () => {

    const [fundId, setFundId] = useState(null);
    const [addFund, setAddFund] = useState(false);
    const [fundDetail, setFundDetail] = useState(null);
    // const [enabled, setEnabled] = useState(true);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [fundData, setFundData] = useState({});
    const [tabIndex, setTabIndex] = useState(1);
    const [investmentStyle, setInvestmentStyle] = useState("");
    const [investmentTypes, setInvestmentTypes] = useState([]);
    const [asset, setAsset] = useState("");
    const [addAsset, setAddAsset] = useState(false);
    const [fundIds, setFundIds] = useState([]);
    const [goalTypes, setGoalTypes] = useState([]);
    const [goalType, setGoalType] = useState(null);

    useEffect(() => {
        _getFundStyles();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (fundId !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show()
        }
    }, [fundId]);

    useEffect(() => {
        if (addFund === true) {
            const modal = new Modal(document.getElementById("add-funds"));
            modal.show()
        }
    }, [addFund]);

    useEffect(() => {
        if (addAsset === true) {
            const modal = new Modal(document.getElementById("asset-class"));
            modal.show()
        }
    }, [addAsset]);

    function _handleAddAsset(status, detail, fund_ids) {
        setAddAsset(status);
        setAsset(detail);
        setFundIds(fund_ids);
    }

    function _handleDeleteFund(id) {
        setFundId(id);
    }

    function _handleAddFund(status, detail, asset_class) {
        if (asset_class && detail) {
            const all_funds = detail[`${asset_class}`]
            const filtered = all_funds.filter((item) => item.goal_type_id === goalType)
            const result = {
                [asset_class]: filtered
            }
            setFundDetail(result);
        } else {
            setFundDetail(detail);
        }
        setAddFund(status);
        setAsset(asset_class);
    }

    function _handleTab(tab_index, data) {
        setTabIndex(tab_index);
        setInvestmentStyle(data.label);
    }

    function _handleGoalType(tab_index, data) {
        setGoalType(tab_index);
    }

    const _getDescription = () => {
        const index = investmentTypes.findIndex((item) => item.id === tabIndex);
        return investmentTypes[index].description;
    }

    // API - get Fund styles
    const _getFundStyles = () => {
        const url = "/fund/investment_styles";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const investment_types = []
                response.data.map((item) => {
                    return (
                        investment_types.push({
                            label: item.name,
                            id: item.id,
                            description: item.description
                        })
                    )
                })
                setInvestmentTypes(investment_types);
                setTabIndex(investment_types[0].id);
                setInvestmentStyle(investment_types[0].label);
                _getFundList();
                _getGoalData();
            }
        });
    }

    // API - get Fund styles
    const _getGoalData = () => {
        const url = "/fund/goal-list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalTypes(response.data);
                setGoalType(response.data[0].id);
            }
        });
    }

    // API - get fund list
    const _getFundList = () => {
        const url = "/fund/list";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFundData(response.data);
            }
            setPageLoader(false);
        });
    }

    //API- delete fund
    const _deleteFund = async () => {
        let status = false;
        setApiLoader(true);
        const url = "/fund/delete";
        const request = {
            allocation_ids: [fundId]
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                status = true;
                _getFundList();
                toast.success("Fund deleted successfully", {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false)
        });
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage MF" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className="padding-32px-lr mt-3">
                                {
                                    pageLoader === true ?
                                        <div className="h-80vh d-flex align-items-center justify-content-center">
                                            <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                                alt={"loader"}
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        :
                                        <Fragment>
                                            <div className="d-flex justify-content-between">
                                                <CustomTabBar data={investmentTypes}
                                                    selectedTab={tabIndex}
                                                    onSelectTab={_handleTab} />

                                                <PrimaryButton label="Add MF"
                                                    className="padding-60px-lr padding-10px-tb"
                                                    handleClick={() => _handleAddFund(true, null, '')} />
                                            </div>
                                            <CustomTabBar data={goalTypes}
                                                selectedTab={goalType}
                                                onSelectTab={_handleGoalType} />
                                            <p className="my-3 color-dark-charcoal e-font-14 e-montserrat-semi-bold e-line-height-24">
                                                {_getDescription()}
                                            </p>
                                            {
                                                Object.entries(fundData[`${investmentStyle}`]).map(([item, funds]) => (
                                                    //eslint-disable-next-line
                                                    Object.entries(funds).map(([item2, fund2], key) => {
                                                        if (fund2.some((item) => item.goal_type_id === goalType)) {
                                                            return (
                                                                <div key={key} className="e-box-shadow-black36 border-radius-12px p-3 mb-3">
                                                                    <div className="d-flex justify-content-between align-items-center px-3">
                                                                        <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                                            {item2}
                                                                        </h6>
                                                                        <TextButton name="Edit asset class"
                                                                            hide_arrow={true}
                                                                            className="e-montserrat-bold e-line-height-24 e-font-14"
                                                                            onPress={() => _handleAddAsset(true, item2, fund2)} />

                                                                    </div>
                                                                    <div className="e-bg-pastel-lavender p-3 border-radius-12px e-box-shadow-black36 mt-2 pb-0">
                                                                        {
                                                                            fund2.filter((item) => item.goal_type_id === goalType).map((fundObj) => {
                                                                                return (
                                                                                    <div className="row pb-3 align-items-center">
                                                                                        <div className="col-8">
                                                                                            <span className="e-font-12 e-line-height-18 color-dark-charcoal e-montserrat-medium">{fundObj.name} <span className="e-montserrat-bold">({fundObj.fund_alias})</span></span>
                                                                                        </div>
                                                                                        <div className="col-3 text-end">
                                                                                            <span className="e-font-14 e-line-height-20 color-dark-charcoal e-montserrat-semi-bold">{fundObj.allocation}%</span>
                                                                                        </div>
                                                                                        <div className="col-1">
                                                                                            <TextButton hide_arrow={true} name="Delete"
                                                                                                className="color-deep-carmine-pink" onPress={() => _handleDeleteFund(fundObj.id)} />
                                                                                        </div>
                                                                                    </div>
                                                                                )

                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        fund2.filter((item) => item.goal_type_id === goalType).slice(0, 1).map((fundObj) => {
                                                                            return (
                                                                                <div className="d-flex align-items-center gap-8px mt-3">
                                                                                    <TextButton name="Edit Allocation" hide_arrow={true}
                                                                                        onPress={() => _handleAddFund(true, funds, item2)} />

                                                                                    <SecondaryButton label="Add More Funds" hide_arrow={true}
                                                                                        className="padding-12px-tb px-4"
                                                                                        handleClick={() => _handleAddFund(true, null, item2)} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    })
                                                ))
                                            }
                                        </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                fundId !== null &&
                <ConfirmDeleteModal title="Delete MF"
                    content="Are you sure you want to delete this mutual fund? This action cannot be undone."
                    delete={_deleteFund}
                    loader={apiLoader}
                    updateList={_getFundList}
                    closeModal={() => _handleDeleteFund(null)} />
            }

            {
                addFund === true && <AddFundsModal
                    data={fundDetail}
                    type={tabIndex}
                    asset={asset}
                    goalType={goalType}
                    closeModal={() => _handleAddFund(false, null, "")}
                    updateList={_getFundList} />
            }
            {
                addAsset === true && <ManageAssetClass
                    asset={asset}
                    fundIds={fundIds}
                    closeModal={() => _handleAddAsset(false, null, [])}
                    updateList={_getFundList} />
            }
        </Fragment >
    )
}

export default FundListing;