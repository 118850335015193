/*
 *   File : forgot-password.js
 *   Author : https://evoqins.com
 *   Description : Auth file to reset password Admin
 *   Version : 1.0.0
*/
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import components
import { PrimaryButton } from "../../Components/Buttons"
import { CustomPINInput, CustomPasswordInput, CustomTextInput1 } from "../../Components/FormElements"
import { Icon } from "../../Components/Icon";

// import services
import APIService from "../../Services/api-service";
import { _emailValidator, useCountdown } from "../../Helper/helper";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { seconds, resetCountdown } = useCountdown(30);

    const [authStep, setAuthStep] = useState(1);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [tempToken, setTempToken] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            setAuthStep(location.state.step);
            setEmail(location.state.email);
            if (location.state.otp) {
                setOtp(location.state.otp)
            }
        } else {
            setAuthStep(1);
        }
    }, [location.state])

    useEffect(() => {
        if (authStep === 2) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [authStep]);

    // name handler
    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    // password handler
    function _handlePassword(input_value) {
        setPassword(input_value);
        setPasswordError("");
    }

    // confirm password handler
    function _handleConfirmPassword(input_value) {
        setConfirmPassword(input_value);
        setConfirmPasswordError("");
    }

    // otp value handler
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // handle resend OTP
    function _handleResend() {
        resetCountdown();
        setOtp("");
        _forgotPassword("RESEND")
    }

    // password toggle
    function _handlePasswordToggle() {
        setShowPassword(!showPassword)
    }

    // password toggle
    function _handleConfirmPasswordToggle() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    function _submitEmail() {
        let valid = true;

        if (email === "") {
            valid = false;
            setEmailError("Email cannot be empty");
        }

        if (email !== "" && _emailValidator(email) === false) {
            valid = false;
            setEmailError("Invalid email");
        }

        if (valid === true) {
            _forgotPassword();
        }

    }

    // otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }

        if (valid === true) {
            _verifyOTP();
        }
    }

    function _submitPassword() {

        let valid = true;

        // const password_regex = /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        }

        if (password !== "" && password.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }

        // if (password !== "" && !password_regex.test(password)) {
        //     setPasswordError("Password minimum criteria doesn’t met");
        //     valid = false;
        // }

        if (confirmPassword === "") {
            setConfirmPasswordError("Confirm password is required");
            valid = false;
        }

        if (confirmPassword !== "" && confirmPassword.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }
        if (confirmPassword !== "" && password !== "" && password !== confirmPassword) {
            setConfirmPasswordError("Password not matching");
            valid = false;
        }

        if (valid === true) {
            _resetPassword();
        }
    }

    // API - forgot password
    const _forgotPassword = (type = null) => {
        if (type === null) {
            setApiLoader(true);
        }
        const url = "/auth/forgot-password";
        const request = {
            "email": email,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status === true) {
                setAuthStep(2);
                navigate("/forgot-password", {
                    state: {
                        step: 2,
                        email: email
                    }
                })
                if (type === null) {
                    toast.success('OTP sent successfully', {
                        type: 'success'
                    });
                } else {
                    toast.success('OTP resent successfully', {
                        type: 'success'
                    });
                }
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    // API - Verify OTP
    const _verifyOTP = () => {
        setApiLoader(true);
        const url = "/auth/verify-otp";

        const request = {
            "email": email,
            "otp": otp,
            "type": "RESET_PASSWORD"
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status === true) {
                toast.success('OTP verified successfully', {
                    type: 'success'
                });
                setTempToken(response.access_token.temp_token);
                setAuthStep(3);
                navigate("/forgot-password", {
                    state: {
                        step: 3,
                        email: email,
                        otp: otp
                    }
                })
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        })
    }

    // API - set new password
    const _resetPassword = () => {
        setApiLoader(true);
        const url = "/auth/reset-password";

        const request = {
            "password": confirmPassword,
            "temp_token": tempToken
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status === true) {
                toast.success('Password updated successfully', {
                    type: 'success'
                });
                navigate("/");
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        })
    }


    return (
        <div className="padding-64px-tb text-center e-login h-100">
            <img src={require("../../Assets/Images/LeftNavigation/mfnxt-logo.svg").default}
                alt="logo"
                width={120}
                height={100}
                className="mx-auto cursor-pointer object-fit-contain bg-white px-3"
                onClick={() => navigate("/")} />
            {
                // -------Email ---------
                authStep === 1 ?

                    <div className="margin-44px-top padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">

                        <Icon icon="back"
                            size={24}
                            onClick={() => navigate("/", {
                                state: {
                                    step: 1,
                                    username: location.state.username,
                                    password: location.state.password
                                }
                            })}
                            className="cursor-pointer" />

                        <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal my-4">
                            User authentication
                        </h3>
                        <CustomTextInput1 label="Enter the registered email"
                            postfix="*"
                            type="email"
                            placeHolder="Enter registered email"
                            inputClass="text-transform-lower"
                            value={email}
                            error={emailError}
                            handleChange={_handleEmail}
                            onSubmit={_submitEmail} />

                        <div className="col-5 d-block margin-32px-top">
                            <PrimaryButton label="Confirm" className="w-100 padding-14px-tb"
                                handleClick={_submitEmail}
                                disabled={apiLoader}
                                loader={apiLoader} />
                        </div>

                    </div>
                    :

                    // ------------- OTP input ---------------
                    authStep === 2 ?
                        <div className="margin-34px-top padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">
                            <Icon icon="back"
                                size={24}
                                onClick={() => {
                                    setAuthStep(1);
                                    navigate(-1)
                                }}
                                className="cursor-pointer" />
                            <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4 margin-32px-top">
                                OTP verification
                            </h3>
                            <CustomPINInput postfix="*"
                                autoFocus={true}
                                id="pin-input"
                                title="Enter the otp sent to the registered email"
                                pinString={otp}
                                error={otpError}
                                handleChange={_handleOTP}
                                onSubmit={_submitOTP} />


                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`color-navy-blue e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`}
                                onClick={_handleResend}>{seconds === 0 ? 'Resend SMS' : ` Resend SMS in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>


                            <div className="col-5 d-block margin-58px-top">
                                <PrimaryButton label="Verify & Login"
                                    className="w-100 padding-14px-tb"
                                    loader={apiLoader}
                                    disabled={apiLoader}
                                    handleClick={_submitOTP} />
                            </div>

                        </div>
                        :

                        // ---------------- Set Password  ---------------
                        <div className="margin-34px-top padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">
                            <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4 ">
                                Change password
                            </h3>

                            <div className="e-bg-light-purple px-2 pb-2 pt-1 border-radius-12px d-flex gap-4px align-items-start  mb-4">
                                <span>
                                    <Icon icon="circle-information" size={16} />
                                </span>
                                <span className="e-font-12 e-line-height-18 e-montserrat-regular color-dark-gray pt-1">
                                    Your new password must be at least 8 characters long.
                                    It should include a mix of uppercase and lowercase letters, numbers, and special characters.
                                </span>
                            </div>

                            <CustomPasswordInput label="Password"
                                postfix="*"
                                type={showPassword === true ? "text" : "password"}
                                value={password}
                                error={passwordError}
                                placeHolder="Enter password"
                                show={showPassword}
                                togglePassword={_handlePasswordToggle}
                                onSubmit={_submitPassword}
                                handleChange={_handlePassword} />

                            <CustomPasswordInput label="Confirm password"
                                postfix="*"
                                type={showConfirmPassword === true ? "text" : "password"}
                                value={confirmPassword}
                                error={confirmPasswordError}
                                placeHolder="Confirm password"
                                className='mt-4'
                                show={showConfirmPassword}
                                onSubmit={_submitPassword}
                                togglePassword={_handleConfirmPasswordToggle}
                                handleChange={_handleConfirmPassword} />


                            <div className="col-4 d-block margin-32px-top">
                                <PrimaryButton label="Confirm"
                                    className="w-100 padding-14px-tb"
                                    loader={apiLoader}
                                    disabled={apiLoader}
                                    handleClick={_submitPassword} />
                            </div>


                        </div>
            }
        </div>
    )
}

export default ForgotPassword