/*
 *   File : login.js
 *   Author : https://evoqins.com
 *   Description : Auth file to login Admin
 *   Version : 1.0.0
*/
// import packages
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import components
import { PrimaryButton, TextButton } from "../../Components/Buttons"
import { CustomPINInput, CustomPasswordInput, CustomTextInput1 } from "../../Components/FormElements"
import { useCountdown } from "../../Helper/helper";
import { Icon } from "../../Components/Icon";

// import services
import APIService from "../../Services/api-service";

// import store
import store from "../../Store";


const Login = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { seconds, resetCountdown } = useCountdown(30);

    const [authStep, setAuthStep] = useState(1);

    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState("");

    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);


    useEffect(() => {
        if (authStep === 2) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [authStep]);


    useEffect(() => {
        if (location.state !== null) {
            console.log(location)
            setAuthStep(location.state.step);
            setUserName(location.state.username);
            setPassword(location.state.password);
            setEmail(location.state.email)
        } else {
            setAuthStep(1);
        }
        // eslint-disable-next-line
    }, [location.state])

    // name handler
    function _handleUserName(input_value) {
        setUserName(input_value);
        setUserNameError("");
    }

    // password handler
    function _handlePassword(input_value) {
        setPassword(input_value);
        setPasswordError("");
    }

    // otp value handler
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // handle resend OTP
    function _handleResend() {
        resetCountdown();
        setOtp("");
        // _login("RESEND");
        _resend();
    }

    // API - login
    const _resend = () => {
        setApiLoader(true);

        const url = "/auth/resend";

        const request = {
            "email": email,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status_code === 200) {
                toast.success('OTP resent successfully', {
                    type: 'success'
                });

            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    // password toggle
    function _handlePasswordToggle() {
        setShowPassword(!showPassword)
    }


    function _submitLogin() {
        let valid = true;


        if (userName === "") {
            setUserNameError("Username is required");
            valid = false;
        }
        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        }

        if (password !== "" && password.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }
        if (valid === true) {
            _login()
        }
    }

    // otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }

        if (valid === true) {
            _verifyOTP();
        }
    }

    // API - login
    const _login = (type = null) => {
        if (type === null) {
            setApiLoader(true);
        }

        const url = "/auth/login";

        const request = {
            "username": userName,
            "password": password
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status_code === 200) {
                setAuthStep(2);
                setEmail(response.data.email);
                setPassword("");
                navigate("/", {
                    state: {
                        step: 2,
                        username: userName,
                        password: password,
                        email: response.data.email
                    }
                })
                if (type === null) {
                    toast.success('OTP sent successfully', {
                        type: 'success'
                    });
                } else {
                    toast.success('OTP resent successfully', {
                        type: 'success'
                    });
                }
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }


    // API - Verify OTP
    const _verifyOTP = () => {
        setApiLoader(true);
        const url = "/auth/verify-otp";

        const request = {
            "email": email,
            "otp": otp,
            "type": "LOGIN"
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status === true) {
                sessionStorage.setItem("finozone_access_token", response.access_token.token);
                store.dispatch({
                    type: "LOGIN_STATUS",
                    payload: true
                });

                navigate("/", {
                    replace: true,
                });
                toast.success('OTP verified successfully', {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        })
    }

    return (
        <div className="padding-64px-tb text-center e-login">
            <img src={require("../../Assets/Images/LeftNavigation/mfnxt-logo.svg").default}
                alt="logo"
                width={120}
                height={100}
                className="mx-auto cursor-pointer object-fit-contain bg-white px-3"
                onClick={() => navigate("/")} />
            {/* <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4">MFNXT</h3> */}

            {
                authStep === 1 ?

                    // ---------------- E-mail & Password -------------------
                    <div className="margin-54px-top padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">
                        <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4">Admin login</h3>
                        <CustomTextInput1 label="Username"
                            postfix="*"
                            type="text"
                            placeHolder="Enter username"
                            value={userName}
                            error={userNameError}
                            handleChange={_handleUserName}
                            onSubmit={_submitLogin} />

                        <CustomPasswordInput label="Password"
                            postfix="*"
                            type={showPassword === true ? "text" : "password"}
                            value={password}
                            error={passwordError}
                            placeHolder="Enter password"
                            className='mt-2'
                            handleChange={_handlePassword}
                            show={showPassword}
                            togglePassword={_handlePasswordToggle}
                            onSubmit={_submitLogin} />

                        <div className="d-flex justify-content-end margin-10px-top">
                            <TextButton name="Forgot password?" className="e-font-14 e-line-height-18"
                                hide_arrow={true}
                                onPress={() => navigate("/forgot-password", {
                                    state: {
                                        step: 1,
                                        username: userName,
                                        password: password
                                    }
                                })} />
                        </div>

                        <div className="col-4 d-block mt-3">
                            <PrimaryButton label="Login" className="w-100 padding-14px-tb"
                                loader={apiLoader}
                                disabled={apiLoader}
                                handleClick={_submitLogin} />
                        </div>

                    </div>
                    :

                    // ------------------- Enter OTP -----------------
                    <div className="margin-54px-top padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">
                        <Icon icon="back"
                            size={24}
                            onClick={() => {
                                navigate(-1)
                                setOtp("");
                            }}
                            className="cursor-pointer" />
                        <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4 margin-32px-top">
                            OTP verification
                        </h3>
                        <CustomPINInput postfix="*"
                            autoFocus={true}
                            id="pin-input"
                            title="Enter the otp sent to the registered email"
                            pinString={otp}
                            error={otpError}
                            onSubmit={_submitOTP}
                            handleChange={_handleOTP} />


                        <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`color-navy-blue e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`}
                            onClick={_handleResend}>{seconds === 0 ? 'Resend SMS' : ` Resend SMS in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>


                        <div className="col-5 d-block margin-58px-top">
                            <PrimaryButton label="Verify & Login" className="w-100 padding-14px-tb"
                                loader={apiLoader}
                                disabled={apiLoader}
                                handleClick={_submitOTP} />
                        </div>

                    </div>
            }
        </div>
    )
}

export default Login